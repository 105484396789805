// 根据环境不同引入不同api地址
import axios from "axios";
import encrypt from "./encrypt";
const service = axios.create({
  baseURL: "https://api.youpaizhaohuo.cn",
  timeout: 30000,
});
service.interceptors.request.use(
  async (config) => {
    // 加密参数
    let encryptData;
    let stamp = new Date().getTime().toString();
    if (config.method.toLowerCase() === "get") {
      console.log("🚀 request - params", config.params);
      encryptData = encrypt.Encrypt({ ...config.params, stamp });
      config.params = { data: encryptData };
    } else if (config.method.toLowerCase() === "post") {
      console.log("🚀 request - data", config.data);
      encryptData = encrypt.Encrypt({ ...config.data, stamp });
      config.params = { data: encryptData };
      // 加密后是否清除data数据
      if (!config.clearData) {
        config.data = null;
      }
    }

    // 请求头
    config.headers = {
      ...config.headers,
      siteId: "6b3d00fbe83941f8a084ec9e4cfcac0a",
      osVersion: "4",
      sign: encrypt.MD5(encryptData, stamp),
    };
    encryptData = null;
    stamp = null;
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);
// respone拦截器
service.interceptors.response.use(
  (response) => {
    const interceptor = new DefaultHttpInterceptor();
    // 得到正常返回数据和错误返回
    const res =
      response.code != null && response.code !== undefined
        ? response
        : response.data;
    const flag = interceptor.handleResponse(res.code, res.message, res.data);
    if (flag) {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res || "error");
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 处理返回数据
class DefaultHttpInterceptor {
  constructor() {
    // 正常放行code
    this.CODE_SUCCESS = [0, 200];
  }
  handleResponse(code, message, data) {
    if (this.CODE_SUCCESS.indexOf(code) !== -1) {
      return true;
    } else {
      const error = new ServerError(code, message, data);
      error.processError();
    }
  }
}

// 服务器返回错误
class ServerError {
  constructor(code, message, data) {
    // 常见错误
    this.ERROR_CODE_UNAUTH = [10, 40, 402, 403, 500, 1000];
    // token异常
    this.ERROR_CODE_SERVER_ERROR_TOKEN = 401;
    this.code = code;
    this.message = message;
    this.data = data;
    // this.callback = callback;
  }

  processError() {
    if (this.ERROR_CODE_UNAUTH.indexOf(this.code) !== -1) {
      this.processServerError();
    } else if (this.code === this.ERROR_CODE_SERVER_ERROR_TOKEN) {
      this.processServerErrorToken();
    } else {
      this.processUnknownError();
    }
  }
  processServerError() {}
  processServerErrorToken() {}
  processUnknownError() {}
}

export default service;
