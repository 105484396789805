<template>
  <div class="content">
    <div class="content-b">
      <div class="header">
        <img src="../assets/index/logo_text.png" class="logo" />
      </div>
      <div class="main">
        <div class="flex-box">
          <div class="con">
            <div class="line_1">优派找活App</div>
            <div class="line_2">优派找活是一款蓝领行业的的招工求职软件</div>
            <div class="line_3">
              将蓝领企业与工人建立有效连接，真正解决企业招工荒、工人求职难的问题；精准匹配老板与工人信息，随时随地查看联系，在线就能完成招工找活，让老板招工更方便，工友轻松找到好工作；
            </div>
            <div class="flex-nowrap">
              <div class="download-app" @click="downloadApp">下载APP</div>
              <div class="install">扫码下载</div>
              <div class="installCode">
                扫码下载
                <div class="inPic qrcode"></div>
              </div>
            </div>
          </div>
          <img class="main_bg" src="../assets/index/phone.png" />
        </div>
      </div>
      <div class="step">
        <div class="title">服务行业</div>
        <div class="stepList">
          <div
            :class="['list', 'list' + (index + 1)]"
            v-for="(item, index) in list"
            :key="item.title"
          >
            <img :class="['icon', 'icon' + (index + 1)]" :src="item.url" />
            <div class="txt">{{ item.title }}</div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="bottom">
          <div class="left">
            <p class="top_title">联系我们</p>
            <p>官方联系电话：400-999-6239</p>
            <p>工作时间：9:00~21:00</p>
            <p>备案号：蜀ICP备2023014278号-2</p>
            <p>
              copyright © 2023-至今 成都只航科技有限公司 ALL Rights Reserved
            </p>
          </div>
          <div class="right">
            <img src="../assets/index/logo.png" class="foo_logo" />
            <span class="right_title">优派找活</span>
          </div>
        </div>
      </div>
    </div>

    <div class="content-s">
      <div class="header">
        <img src="../assets/index/logo_text_btline.png" class="logo" />
      </div>
      <div class="s-title">优派找活App</div>
      <div class="s-hint">优派找活是一款蓝领行业的的招工求职软件</div>
      <div class="s-hint">
        将蓝领企业与工人建立有效连接，真正解决企业招工荒、
      </div>
      <div class="s-hint">工人求职难的问题；</div>
      <img src="../assets/index/phone_s.png" class="phone-s" />
      <div class="call-me">联系我们</div>
      <div class="footer-hint">
        <div>官方联系电话：400-999-6239</div>
        <div>工作时间：9:00~21:00</div>
        <div>备案号：蜀ICP备2023014278号-2</div>
        <div>
          copyright © 2023-至今 成都只航科技有限公司 ALL Rights Reserved
        </div>
      </div>
      <div class="fiexd-btn">
        <div @click="downloadApp">下载app</div>
      </div>
    </div>
  </div>
</template>

<script>
import { installUrl } from "../api/install";
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      list: [
        {
          title: "建筑工程",
          url: require("../assets/index/icon_1.png"),
        },
        {
          title: "装修安装",
          url: require("../assets/index/icon_2.png"),
        },
        {
          title: "货运物流",
          url: require("../assets/index/icon_3.png"),
        },
        {
          title: "家政服务",
          url: require("../assets/index/icon_4.png"),
        },
        {
          title: "生产制造",
          url: require("../assets/index/icon_5.png"),
        },
      ],
      downloadUrl: "",
    };
  },
  mounted() {
    installUrl().then((res) => {
      const code = document.querySelector(".qrcode");
      const instance = new QRCode(code, {
        width: 124,
        height: 124,
        correctLevel: QRCode.CorrectLevel.H,
      });
      instance.makeCode(res.data);
      this.downloadUrl = res.data;
      instance._el.title = "";
      // code.style.borderRadius = "40px";
    });
    const install = document.querySelector(".install");
    const installCode = document.querySelector(".installCode");
    install.onmouseover = function () {
      install.style.display = "none";
      installCode.style.display = "block";
    };
    installCode.onmouseout = function () {
      setTimeout(() => {
        install.style.display = "block";
        installCode.style.display = "none";
      }, 100);
    };
  },
  methods: {
    downloadApp() {
      if (!this.downloadUrl) return;
      window.location.href = this.downloadUrl;
    },
  },
};
</script>

<style scoped>
@media screen and (min-width: 721px) {
  .content {
    width: 100%;
    margin: 0 auto;
    display: flex;
    /* min-width: 1562px; */
    flex-direction: column;
  }
  .content-s {
    display: none;
  }
  .content-b {
    width: 100%;
    margin: 0 auto;
    display: flex;
    /* min-width: 1562px; */
    flex-direction: column;
  }
  .header {
    height: 60px;
    background: #ffffff;
    display: flex;
    align-items: center;
  }
  .logo {
    width: 140px;
    height: 34px;
    margin-left: 10%;
  }
  .main {
    width: 100%;
    height: 740px;
    position: relative;
    background: linear-gradient(180deg, #dcfbe9 0%, #f6fffa 100%);
    display: flex;
    justify-content: center;
  }
  .flex-box {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
  }
  .main_bg {
    width: 400px;
    height: 640px;
    top: 50px;
    right: 335px;
    margin-left: 10%;
  }
  .con {
    width: 33%;
  }
  .line_1 {
    color: #1bcb9b;
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .line_2,
  .line_3 {
    width: 100%;
    color: #666666;
  }
  .install {
    width: 152px;
    height: 53px;
    border-radius: 8px;
    border: 2px solid #1bcb9b;
    color: #1bcb9b;
    font-size: 18px;
    text-align: center;
    line-height: 55px;
    margin-top: 20px;
    margin-left: 20px;
  }
  .download-app {
    width: 152px;
    height: 55px;
    background: #1bcb9b;
    border-radius: 8px;
    color: #ffffff;
    text-align: center;
    line-height: 55px;
    margin-top: 20px;
    position: relative;
    cursor: pointer;
  }
  .installCode {
    width: 152px;
    height: 55px;
    background: #1bcb9b;
    border-radius: 8px;
    color: #ffffff;
    text-align: center;
    line-height: 55px;
    margin-top: 20px;
    display: none;
    position: relative;
    margin-left: 20px;
  }
  .inPic {
    width: 128px;
    height: 128px;
    position: absolute;
    top: 77px;
    left: 0;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ::v-deep .inPic img {
    border-radius: 8px;
  }
  .step {
    width: 100%;
    height: 297px;
    background: #ffffff;
  }
  .title {
    font-size: 32px;
    color: #1bcb9b;
    text-align: center;
    margin: 40px 0px 32px 0px;
    position: relative;
  }
  .title:after {
    content: "";
    width: 80px;
    height: 2px;
    background: #1bcb9b;
    position: absolute;
    bottom: -4px;
    left: 50%;
    margin-left: -40px;
  }
  .stepList {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .list {
    width: 240px;
    height: 100px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(0, 42, 31, 0.05);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    font-size: 20px;
    color: #1bcb9b;
  }
  .icon {
    width: 48px;
    height: 48px;
    margin-right: 20px;
  }
  .footer {
    background: #f2f2f2;
    height: 274px;
  }
  .top_title {
    font-size: 24px;
    font-weight: bold;
    margin: 40px 0px 20px 0px;
  }
  .bottom {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .right {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .foo_logo {
    width: 128px;
    height: 128px;
    margin-top: 40px;
    margin-bottom: 16px;
  }
  .right_title {
    font-size: 18px;
  }
  .list:hover {
    background-color: #1bcb9b;
    color: #ffffff;
  }
  .list1:hover img {
    content: url("../assets/index/iconb_1.png");
  }

  .list2:hover img {
    content: url("../assets/index/iconb_2.png");
  }
  .list3:hover img {
    content: url("../assets/index/iconb_3.png");
  }
  .list4:hover img {
    content: url("../assets/index/iconb_4.png");
  }
  .list5:hover img {
    content: url("../assets/index/iconb_5.png");
  }
  .flex-nowrap {
    display: flex;
    flex-wrap: nowrap;
  }
}

@media screen and (max-width: 720px) {
  .content-s {
    width: 100%;
    margin: 0 auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #dcfbe9 0%, #f6fffa 100%);
  }
  .content-b {
    display: none;
  }
  .logo {
    width: 70px;
    height: 23px;
    margin: 10px 0 0 10px;
  }
  .s-title {
    font-size: 32px;
    color: #1bcb9b;
    font-weight: bold;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 10px;
  }
  .s-hint {
    font-size: 12px;
    color: #666666;
    text-align: center;
    line-height: 17px;
  }
  .phone-s {
    width: 241px;
    height: 384px;
    margin: 10px auto 0 auto;
  }
  .call-me {
    font-size: 12px;
    color: #333;
    font-weight: bold;
    padding-left: 10px;
  }
  .footer-hint {
    padding-left: 10px;
    font-size: 10px;
    color: #999;
    line-height: 14px;
  }
  .fiexd-btn {
    width: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 79px;
  }
  .fiexd-btn > div {
    width: 298px;
    height: 49px;
    background: #1bcb9b;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #fff;
    font-weight: bold;
    animation: shanshuo 0.8s infinite;
  }
}
@keyframes shanshuo {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.8);
  }
}
</style>
