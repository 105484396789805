/*
 * @Author: 陈小豆
 * @Date: 2021-11-23 10:41:35
 * @LastEditors: 陈小豆
 * @LastEditTime: 2021-11-23 16:15:41
 */
import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
import Index from "../views/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
    meta: {
      showMenu: true,
      showIndex: 1,
    },
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
    meta: {
      showMenu: true,
      showIndex: 2,
    },
  },
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
