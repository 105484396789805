import CryptoJS from "crypto-js";

const secret = "recruitjihyrvjos";
const key = "kinhgtps-9d14-4013-8255-9168f24ceacd";
const iv = "cdkjytgsrj754921";

// 加密
function Encrypt(text) {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(text),
    CryptoJS.enc.Utf8.parse(secret),
    {
      iv: CryptoJS.enc.Utf8.parse(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
  return encrypted.toString();
}

// 解密
function Decrypt(text) {
  const decrypted = CryptoJS.AES.decrypt(
    text,
    CryptoJS.enc.Utf8.parse(secret),
    {
      iv: CryptoJS.enc.Utf8.parse(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
  return decrypted.toString(CryptoJS.enc.Utf8);
}

// 组装md5
function MD5(text, stamp) {
  return CryptoJS.MD5(text + key + stamp).toString();
}

export default {
  Encrypt,
  Decrypt,
  MD5,
};
