<!--
 * @Author: 陈小豆
 * @Date: 2021-11-23 10:41:35
 * @LastEditors: 陈小豆
 * @LastEditTime: 2021-11-23 15:36:28
-->
<template>
  <div id="app">
    <!-- <topNav v-show="$route.meta.showMenu"></topNav> -->
    <router-view />
    <!-- <bottomNav v-show="$route.meta.showMenu"></bottomNav> -->
  </div>
</template>

<script>
// import topNav from "@/components/topNav";
// import bottomNav from "@/components/bottomNav";
export default {
  components: {
    // topNav,
    // bottomNav,
  },
  created() {
    window.addEventListener("beforeunload", function () {
      window.scrollTo(0, 0);
    });
  },
};
</script>

<style>
#app {
  padding: 0;
  margin: 0;
}
body {
  padding: 0 !important;
  margin: 0 !important;
}
</style>
